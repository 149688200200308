<template>
  <div class="hello">
<!--    <h1>Регистрация</h1>-->
    <form>
<!--      <div class="input-wrapper-wrapper">-->
<!--        <div class="input-wrapper">-->
<!--          <div class="input-subwrapper">-->
<!--            <input type="email" placeholder="Email" v-model="userEmail">-->
<!--            <label v-if="!$v.userEmail.invalid && submitted">Введите корректный email!</label>-->
<!--          </div>-->
<!--          <div class="input-subwrapper">-->
<!--            <input type="password" placeholder="Пароль" v-model.trim="password">-->
<!--            <label v-if="!$v.password.invalid && submitted">Слишком простой пароль!</label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="input-wrapper-wrapper">
        <div class="input-wrapper">
          <a href="https://partner.spacelead.pro/login"><div class="signIn">
            <h2><img src="../assets/User.svg" alt=""> Вход</h2>
            <p>Если уже зарегистрирован</p>
          </div></a>
          <a href="https://partner.spacelead.pro/registration"><input type="button" value="Регистрация" :disabled="submitted"></a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'HelloWorld',
  props: {
    userEmail: String,
    password: String,
  },
  data: function () {
    return {
      submitted: false
    }
  },
  methods: {
    register: function () {
      fetch('https://spacelead.partner.alanbase.com/registration', {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        return res.text()
      }).then(function (res) {
        const word = 'csrfToken":"'
        let start = res.indexOf(word);
        start = start + word.length;
        const end = res.indexOf('"', start+1);
        console.log(res.substring(start, end));
      });

      // this.submitted = true;
      // this.$v.$touch();
      //
      // if (!this.$v.$invalid) {
      //   alert(this.userEmail);
      // }
    }
  },
  validations: {
    userEmail: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  margin: 0 auto 2rem;
  color: #B8B8B8;
  font-weight: 400 !important;
}

h1 {
  color: #ffffff;
  margin: 0;
  margin-bottom: 2rem;
}

label {
  margin-top: .25rem;
  color: #ffffff;
}

input, .signIn {
  color: #ffffff;
  border: #B8B8B8 solid .075rem;
  border-radius: .3rem;
  background: transparent;
  width: 14rem;
  font-size: 1rem;
  padding: .5rem 1rem;
  outline: none;
  box-sizing: border-box;
  min-height: 4rem;
}

input:focus {
  border: #ffffff solid .1rem;
}

input[type=button], .signIn {
  background: #460686;
  border: #460686 solid .075rem;
  cursor: pointer;
}

a {
  color: #42b983;
  cursor: pointer;
  text-decoration: none !important;
}

.input-subwrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.input-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  width: fit-content;
  max-width: 100vw;
  gap: 1rem;
  margin: auto;
  margin-bottom: 1rem;
}

.input-wrapper-wrapper {
  display: flex;
}

.signIn {
  background: transparent;
  text-align: left;
}

.signIn h2 {
  margin: 0;
  color: #ffffff;
}

.signIn p {
  margin: 0;
  font-size: 0.75rem;
  color: #B8B8B8;
  padding-bottom: .05rem;
}
</style>
