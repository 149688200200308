<template>
  <div id="app">
    <img alt="SpaceLead" src="./assets/SpaceLead.svg" style="width: 30rem;padding-bottom: 0.5rem;max-width: calc(100% - 1rem);">
    <h2>AFFILIATE NETWORK</h2>
    <Registration />
    <hr />
    <div class="links">
      <a href="https://t.me/spaceleadsupport"><img src="./assets/Telegram.svg" alt="Telegram"></a>
      <a href="mailto:spaceleadsup@gmail.com"><img src="./assets/Email.svg" alt="Email"></a>
    </div>
  </div>
</template>

<script>
import Registration from './components/Registration.vue'

export default {
  name: 'App',
  components: {
    Registration
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: fit-content;
  margin: auto;
  font-weight: 400 !important;
  z-index: 100;
}

h2 {
  margin: 0;
  margin-bottom: 3rem;
  font-weight: 300 !important;
}

hr {
  border: none;
  border-top: #ffffff solid 1px;
  margin: 1rem auto;
}

a {
  color: #B8B8B8;
}

.links {
  display: grid;
  width: 6rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(2.5rem, 1fr));
  margin: auto;
}

.links img {
  width: 2.5rem;
}
</style>
